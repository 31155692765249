import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {  Form } from "react-bootstrap";
import {AiFillEye,AiFillEyeInvisible} from "react-icons/ai"
import ButtonComponent from "../../Components/Button/ButtonComponent";
import { forgotPassword } from "../../Constants/Api/Api";
import Alert from "../../Constants/alert/Alert";
import "./ForgotPassword.scss"
const ForgotPassword = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const received = useLocation();
  const email = received.state;

  const handleChange = (e) => {
    const enteredPassword = e.target.value;
    setPassword(enteredPassword);
    setPasswordError(!isPasswordStrong(enteredPassword));
  };
  const isPasswordStrong = (enteredPassword) => {
    const passwordRegex =
      /^(?=.*[!@#$%^&()\-_=+<>?/{}~])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/;
    
    return passwordRegex.test(enteredPassword);
  };
  const handleConfirmPasswordChange = (e) => {
    const enteredConfirmPassword = e.target.value;
    setConfirm_password(enteredConfirmPassword);
    if (enteredConfirmPassword === "") {
      setPasswordMatchError(false);
    } else {
      setPasswordMatchError(password !== enteredConfirmPassword);
    }
  };

  const resetPassword = () => {
    const token = localStorage.getItem("forotpassword")
    forgotPassword(email, password, confirm_password,token)
      .then((res) => {
        // console.log("res",res)
        if (res.data.status === 200) {
          setShowAlert(true);
          setAlertConfig({
            text: res.data.message,
            icon: "success",
          });
          setTimeout(() => {
            setShowAlert(false);
            navigate("/login");
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response.status > 200) {
          setShowAlert(true);
          setAlertConfig({
            text: "Password could not reset successfully",
            icon: "info",
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 1000);
        }
      });
  };
  const handleClickShowPassword = () =>
    setShowPassword((showPassword) => !showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="main_reset_section">
        <div className="reset_form_container">
          <h2>Reset Your password here!</h2>
          <Form>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                // onChange={handleChange}
                placeholder="please enter your email"
                type="text"
                name="email"
                value={email}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label className="mt-2">Password</Form.Label>
              <div className="input-container">
                <input
                  value={password}
                  className="form-control clientInput"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                />
                <span className="eyesHidden">
                  <p
                     onClick={handleClickShowPassword}
                     onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <AiFillEye size={25}/> : <AiFillEyeInvisible size={25}/>}
                  </p>
                </span>
              </div>
              {passwordError && (
                <div style={{ color: "red" }}>
                  Password must be at least 8 characters long and contain at
                  least one uppercase letter, one special character, and one
                  number.
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label className="mt-3">Confirm Password</Form.Label>
              <div className="input-container">
                <input
                  value={confirm_password}
                  className="form-control clientInput"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleConfirmPasswordChange}
                />
                <span className="eyesHidden">
                  <p
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <AiFillEye size={25}/> : <AiFillEyeInvisible size={25}/>}
                  </p>
                </span>
              </div>
              {passwordMatchError && (
                <div
                  className="invalid-feedback"
                  style={{ display: "block", color: "red" }}
                >
                  Passwords do not match
                </div>
              )}
            </Form.Group>
          </Form>
          <div className="reset_btn_container">
            <ButtonComponent className="reset_btn" name='Reset Password' onClick={resetPassword}/>
              
            
          </div>
        </div>
        {showAlert ? (
          <Alert text={alertConfig.text} icon={alertConfig.icon} />
        ) : null}
      </div>
     
    </>
  );
};

export default ForgotPassword;
  

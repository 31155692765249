import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Alert from "../../Constants/alert/Alert";
import ButtonComponent from "../../Components/Button/ButtonComponent";
import { sendOtpApi, verifyOtpApi } from "../../Constants/Api/Api";
import OTPInput from "react-otp-input";
import "../SendOtp/SendOtp.scss";

const Varification = () => {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [otpEnabled, setOtpEnabled] = useState(false); // State to control OTP input
  const [otpFilled, setOtpFilled] = useState(false); // State to track OTP filled
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const [emailError, setEmailError] = useState(""); // State to track email validation error
  const navigate = useNavigate();

  // Regular expression for email validation
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    // Check if the email is valid
    if (value && !emailRegex.test(value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const sendOtp = () => {
    // Validate email before sending OTP
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      return;
    }

    const type = "forgot_password";
    sendOtpApi(email, type)
      .then((res) => {
        // console.log("res", res);
        if (res.status === 200) {
          setOtpEnabled(true);
          setShowAlert(true);
          setAlertConfig({
            text: res.data.message,
            icon: "success",
          });
        }
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      })
      .catch((error) => console.log("error", error));
  };

  const handleVerify = () => {
    // Validate email before verifying OTP
    // if (!emailRegex.test(email)) {
    //   setEmailError("Invalid email address");
    //   return;
    // }

    const type = "forgot_password";
    verifyOtpApi(email, otp, type)
      .then((res) => {
        // console.log("res", res);
        if (res.status === 200) {
          const token = res.data.data.token;
          localStorage.setItem("forotpassword", token);
          setShowAlert(true);
          setAlertConfig({
            text: res.data.message,
            icon: "success",
          });
          setTimeout(() => {
            setShowAlert(false);
            navigate("/forgotpassword",{state:email});
          }, 3000);
        } else {
          setShowAlert(true);
          setAlertConfig({
            text: "Error Occurred",
            icon: "error",
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.data.message) setShowAlert(true);
        setAlertConfig({
          text: error.response.data.message,
          icon: "error",
        });
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      });
  };

  return (
    <div className="main_email_section">
      {showAlert && <Alert text={alertConfig.text} icon={alertConfig.icon} />}
      <div className="email_form_container">
        {otpEnabled === false ? (
          <h3>Enter Your Email</h3>
        ) : (
          <h3>Verify Your OTP</h3>
        )}
        <Form>
          {otpEnabled === false ? (
            <>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your Email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </Form.Group>

              <div className="send_otp_btn_container">
                <ButtonComponent
                  className="send_otp_btn"
                  name="Proceed"
                  onClick={sendOtp}
                />
              </div>
            </>
          ) : (
            <Form.Group controlId="formBasicOTP">
              <div className="otpcontainer">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "40px",
                        height: "40px",
                        fontSize: "18px",
                        textAlign: "center",
                        color: "black",
                        background: "white",
                        border: "2px solid #6102e6",
                      }}
                    />
                  )}
                />
              </div>
              <div className="send_otp_btn_container">
                <ButtonComponent
                  className="verify_otp_btn"
                  name="Verify OTP"
                  onClick={handleVerify}
                  disabled={!otpFilled} // Enable or disable Verify OTP button
                />
              </div>
            </Form.Group>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Varification;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { getMyQuestionsApi } from "../Constants/Api/Api";


export const getMyQuery = createAsyncThunk(
  "getmyquery",
  async ({token,id,perpage,page,sortBy}) => {
          
    try {
      const response = await getMyQuestionsApi(token, id, perpage, page,sortBy);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const myQuerySlice = createSlice({
  name: "getmyquery",
  initialState: {
    query: [],
    loading: false,
    error: null,
  },
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      .addCase(getMyQuery.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyQuery.fulfilled, (state, action) => {
        state.loading = false;
        state.cart = action.payload; 
      })
      .addCase(getMyQuery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default myQuerySlice.reducer;


import WebRoutes from './WebRoutes';
import './App.css';
import NavbarSection from './Components/NavbarSection/NavbarSection';
import FooterSection from './Components/FooterSection/FooterSection';
import Footer from './Components/Footer/Footer';
import Card from './Components/Card/Card';


function App() {
  return (
    <>
    <NavbarSection/>
    <WebRoutes/>
   
    <Footer/>
    {/* <FooterSection/> */}
    </>
  );
}

export default App;

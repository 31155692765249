import axios from "axios";

const BASE_URL = "https://node.ohwise1.com:5000/api/v1/";

// ***********************************************Registration Api*********************************************
export const register = async (formData) => {
  return await axios.post(`${BASE_URL}user/auth/register`, formData);
};

// ***********************************************Send OTP Api*********************************************
export const sendOtpApi = async (email, type) => {
  const config = {
    email: email,
    type: type,
  };
  return await axios.post(`${BASE_URL}user/auth/otp`, config);
};

// ***********************************************Verify OTP Api*********************************************
export const verifyOtpApi = async (email, otp, type) => {
  const config = {
    email: email,
    otp: otp,
    type: type,
  };
  return await axios.post(`${BASE_URL}user/auth/verify-otp`, config);
};

// ***********************************************Login Api*********************************************
export const loginApi = async (loginData) => {
  return await axios.post(`${BASE_URL}user/auth/login`, loginData);
};

// ***********************************************Get ALL Questions Api*********************************************
export const getAllQuestionApi = async (limit,page,is_answered,sortBy) => {
 
  return await axios.get(`${BASE_URL}post?is_answered=${is_answered}&limit=${limit}&page=${page}&sortBy=${sortBy}`);
};

// ***********************************************Post Question Api*********************************************
export const postQuestionApi = async (question, token) => {
  return await axios.post(
    `${BASE_URL}post`,
    { question },
    {
      headers: {
        "x-access-token": token,
      },
    }
  );
};

// ***********************************************Post Question Api*********************************************
export const updateQuestionApi = async (id, is_private) => {
  return await axios.put(
    `${BASE_URL}post/${id}`,
     {is_private },
  );
};


// ***********************************************Get User Profile Api*********************************************
export const getProfileApi = async (token) => {
  return await axios.get(`${BASE_URL}user/profile`, {
    headers: {
      "x-access-token": token,
    },
  });
};

// ***********************************************Get Question by user Id*********************************************
export const getMyQuestionsApi = async (token, id,limit,page,sortBy) => {
  return await axios.get(`${BASE_URL}post/user/${id}?limit=${limit}&page=${page}&sortBy=${sortBy}`, {
    headers: {
      "x-access-token": token,
    },

  });
};

// ***********************************************Post Review API*********************************************
export const postReviewApi = async (text, token) => {
  return await axios.post(
    `${BASE_URL}review`,
    { text },
    {
      headers: {
        "x-access-token": token,
      },
    }
  );
};

// ***********************************************Get Review API*********************************************
export const getReviewApi = async () => {
  return await axios.get(`${BASE_URL}review`);
};

// ***********************************************Get Logout API*********************************************
export const logoutApi = async (token) => {
  return await axios.get(`${BASE_URL}user/auth/logout`, {
    headers: {
      "x-access-token": token,
    },
  });
};

// ***********************************************Get key*****************************************************
export const getKeyApi = async (token, paymentMethod) => {
 
  return await axios.get(
    `${BASE_URL}payment/stripe/stripe-key?${paymentMethod}`,
    {
      headers: {
        "x-access-token": token,
      },
    }
  );
};

// ***********************************************Stripe Payment API*********************************************
export const stripePayment = async (token, amount, post_id, question) => {
  const config = {
    amount: amount,
    paymentMethod: "card",
    currency: "USD",
    post_id: post_id,
    description: question,
  };

  return await axios.post(
    `${BASE_URL}payment/stripe/create-payment-intent`,
    config,
    {
      headers: {
        "x-access-token": token,
      },
    }
  );
};

// ***********************************************Stripe Payment API*********************************************
export const forgotPassword = async (
  email,
  password,
  confirm_password,
  token
) => {
  const config = {
    email: email,
    password: password,
    confirm_password: confirm_password,
    token: token,
  };

  return await axios.post(
    `${BASE_URL}user/auth/forgot-password`,
    config
  );
};

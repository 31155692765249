import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Alert from "../../Constants/alert/Alert";
import ButtonComponent from "../../Components/Button/ButtonComponent";
import { sendOtpApi, verifyOtpApi } from "../../Constants/Api/Api";
import OTPInput from "react-otp-input";
import "./SendOtp.scss";
const SendOtp = () => {
  const [otp, setOtp] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [otpEnabled, setOtpEnabled] = useState(false); // State to control OTP input
  const [otpFilled, setOtpFilled] = useState(false); // State to track OTP filled
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const navigate = useNavigate();
  const receive = useLocation();
  console.log(receive.state)
  const sendOtp = () => {
    const type =  "email_verification";
    console.log("otp",otp)
    sendOtpApi(receive.state,type)
      .then((res) => {
        // console.log("res", res);
        if (res.status === 200) {
          setOtpEnabled(true);
          setShowAlert(true);
          setAlertConfig({
            text: res.data.message,
            icon: "success",
          });
        }
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      })
      .catch((error) => console.log("error", error));
  };

  const handleVerify = () => {
    const type =  "email_verification"
    verifyOtpApi(receive.state, otp,type)
      .then((res) => {
        // console.log("res", res);
        if (res.status === 200) {
          setShowAlert(true);
          setAlertConfig({
            text: res.data.message,
            icon: "success",
          });
          setTimeout(() => {
            setShowAlert(false);
            navigate("/login");
          }, 3000);
        } else {
          setShowAlert(true);
          setAlertConfig({
            text: "Error Occured",
            icon: "error",
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((error) =>{ 
        console.log("error", error)
        if(error.response.data.message)
          setShowAlert(true);
        setAlertConfig({
          text:error.response.data.message,
          icon:'error'
        })
        setTimeout(()=>{
          setShowAlert(false)
        },2000)
      });
  };

  return (
    <div className="main_email_section">
      {showAlert && <Alert text={alertConfig.text} icon={alertConfig.icon} />}
      <div className="email_form_container">
        {
          otpEnabled === false ? <h3>Verify Your Email</h3> :<h3>Verify Your OTP</h3>
        }
        <Form>
          {otpEnabled === false ? (
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your Email"
                name="email"
                value={receive.state}
                // onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </Form.Group>
          ) : (
            <Form.Group controlId="formBasicEmail">
              {/* <Form.Label className="mt-3">OTP</Form.Label> */}
              <div className="otpcontainer">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "40px",
                        height: "40px",
                        fontSize: "18px",
                        textAlign: "center",

                        color: "black",
                        background: "white",
                        border: "2px solid #6102e6",
                      }}
                    />
                  )}
                />
              </div>
            </Form.Group>
          )}

          <div className="send_otp_btn_container">
            {!otpEnabled ? (
              <ButtonComponent
                className="send_otp_btn"
                name="Proceed"
                onClick={sendOtp}
              />
            ) : (
              <ButtonComponent
                className="verify_otp_btn"
                name="Verify OTP"
                onClick={handleVerify}
                disabled={!otpFilled} // Enable or disable Verify OTP button
              />
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SendOtp;

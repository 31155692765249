import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { postQuestionApi } from "../../Constants/Api/Api";
import Alert from "../../Constants/alert/Alert";
import PaymentModal from "../PaymentModal/PaymentModal";
import { useDispatch } from "react-redux";
import { getMyQuery } from "../../redux/querySlice";
import { jwtDecode } from "jwt-decode";

const CustomModal = (props) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [postId, setPostId] = useState();
  const [questionId, setQuestionId] = useState()
  const [question, setQuestion] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  // let [page, setPage] = useState(1);
  const page = 1;
  const perpage = 10;
  const sortBy = "DESC";
  const dipatch = useDispatch();
  const token = localStorage.getItem("userToken");
  let decoded,id;
  
  if(token){
     decoded = jwtDecode(token);
     id = decoded.sub;
  }
  
 

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value); // Update the state with the input value
  };



  const handlePostQuestion = () => {
    if (token !== null) {
      const trimmedQuestion = question.trim();
      if (!trimmedQuestion) {
        // Check if addQuestion is empty and show an alert
        setShowAlert(true);
        setAlertConfig({
          text: "Please enter your question.",
          icon: "error",
        });
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      } else {
        postQuestionApi(question, token)
          .then((res) => {
            // console.log("res", res);
            if (res.status === 201) {
              dipatch(getMyQuery({token,id,perpage,page,sortBy}))
              setQuestionId(res.data.data.id);
              setQuestion(res.data.data.question);
              setShowPaymentModal(true);
              setQuestion("")
              props.onHide()
              
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    } else {
      setShowAlert(true);
      setAlertConfig({
        text: "Please login first!",
        icon: "error",
      });
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };
 
  return (
    <>
   {showAlert && <Alert text={alertConfig.text} icon={alertConfig.icon}/> } 
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Query
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Add Question</Form.Label>
          <Form.Control
            type="text"
            placeholder="Question"
            value={question} // Bind the input value to the state
            onChange={handleQuestionChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{backgroundColor:'#7D1CB2',border:'none'}}onClick={()=>handlePostQuestion()}>Add Query</Button>
      </Modal.Footer>
    </Modal>
    <PaymentModal 
    postId={id}
    question={question}
    showPaymentModal={showPaymentModal}
    setShowPaymentModal={setShowPaymentModal}
    questionId={questionId}
    page={page}
    perpage={perpage}
    sortBy={sortBy}
    />
    </>
  );
};

export default CustomModal;

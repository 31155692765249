import React from 'react'
import { Button } from 'react-bootstrap'
import "./ButtonComponent.css"
const ButtonComponent = (props) => {
    return (
        <div className='Button_theme'>
            <Button className='buttonTheme'onClick={props.onClick}>{props.name}</Button>
        </div>
    )
}
export default ButtonComponent
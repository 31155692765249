import React,{useState} from "react";
import profile from "../../assets/profile.png";
import { MdArrowForwardIos } from "react-icons/md";

import "./Card.scss";
import CustomModal from "../CustomModal/CustomModal";
const Card = () => {
  const [modalShow, setModalShow] = useState(false);


  const handleOpenModal = () => {
    setModalShow(true); // Set modalShow state to true to open the modal
  };
  return (
    <div className="card_main_container">
      <div className="titile_container">
        <h6>Explore Your Curiosity: What Can You Ask at Oh Wise 1?</h6>
      </div>
      <div className="containt">
        {/* <div className="profile_container">
          <div className="image">
            <img src={profile} alt="profile" />
          </div>
          <div className="content">
            <h5 className="name">Lara Jean</h5>
            <p>Graphic Designer</p>
          </div>
        </div> */}
        <div>
          <div className="discription_container">
            <p className="text" >
              <b>Life's Mysteries:</b> Unravel the secrets of life, love, and
              everything in between.
            </p>
            <MdArrowForwardIos size={40}  onClick={handleOpenModal}/>
          </div>
          <div className="discription_container">
            <p className="text"  >
              <b>Whimsical Wonders:</b>Embark on a journey of fantastical and
              imaginative inquiries.{" "}
            </p>
            <MdArrowForwardIos size={40} onClick={handleOpenModal}/>
          </div>
          <div className="discription_container">
            <p className="text"  >
              <b>Practical Wisdom: </b>Seek advice and insights for everyday
              challenges and decisions.
            </p>
            <MdArrowForwardIos size={40} onClick={handleOpenModal}/>
          </div>
        </div>
      </div>
      <CustomModal show={modalShow} onHide={() => setModalShow(false)}/>
    </div>
  );
};

export default Card;

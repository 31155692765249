import React, { useState } from "react";
import ButtonComponent from "../../Components/Button/ButtonComponent";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import {AiFillEye,AiFillEyeInvisible} from "react-icons/ai"
import { loginApi } from "../../Constants/Api/Api";
import Alert from "../../Constants/alert/Alert";
import "./Login.scss";

const Login = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };


  const handleAlertSubmit = (e) => {
    const missingFields = [];
    if (!loginData.email) missingFields.push("Email");
    if (!loginData.password) missingFields.push("Password");
    if (missingFields.length > 0) {
      setShowAlert(true);
      setAlertConfig({
        icon: "info",
        text: `Please fill in the following required fields: ${missingFields.join(
          ", "
        )}.`,
      });
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } else {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    loginApi(loginData)
      .then((res) => {
        // console.log("res",res)
        const token = res?.data?.data?.tokens?.access?.token;
        if (token) {
          localStorage.removeItem("userToken");
          localStorage.setItem("userToken", token);

          if (res.status === 200) {
            setShowAlert(true);
            setAlertConfig({
              text: res?.data?.message,
              icon: "success",
            });
          }
          setTimeout(() => {
            setShowAlert(false);
            navigate("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.message) {
          setShowAlert(true);
          setAlertConfig({
            text: error.response.data.message,
            icon: "warning",
          });
          setTimeout(()=>{
            setShowAlert(false)
          },2000)
        }
      });
    // Access the form data from the formData state

    // Now you can use these values as needed, e.g., send them to an API for login.
  };

  const handleShowPass = () =>
  setShowPassword((showPassword) => !showPassword);

const handleMouseDownPass = (event) => {
  event.preventDefault();
};

  return (
    <div className="LoginContainer">
      {showAlert && <Alert text={alertConfig.text} icon={alertConfig.icon} />}
      <div className="Login">
        <div className="Login_Container">
          <h2>Login</h2>
          <Form>
            <FormGroup>
              <FormLabel className="mt-3">Email</FormLabel>
              <FormControl
                type="text"
                placeholder="Email"
                name="email"
                value={loginData.email}
                onChange={handleInputChange}
              />
              {!validEmail && (
                <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
                  Invalid email format
                </Form.Control.Feedback>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel className="mt-3">Password</FormLabel>
              <div className="input-container">
              <FormControl
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={loginData.password}
                onChange={handleInputChange}
              />
               <span className="eyesHidden">
                  <p
                     onClick={handleShowPass}
                     onMouseDown={handleMouseDownPass}
                  >
                    {showPassword ? <AiFillEye size={25}/> : <AiFillEyeInvisible size={25}/>}
                  </p>
                </span>
              </div>
            </FormGroup>
            <div className="forotPassword">
              <Link to="/verification">Forgot password ?</Link>
            </div>
            <div className="mt-4">
              <ButtonComponent name="Login" onClick={handleAlertSubmit} />
            </div>
          </Form>
          <p>
            Don't have an account?<Link to="/signup">SignUp</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect } from 'react'
import Swal from "sweetalert2";
const Alert = ({text,icon}) => {
    useEffect(() => {
        const handleClose = () => {
          Swal.close();
        };
    
        Swal.fire({
          text: text,
          icon: icon,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        }).then((result) => {
          handleClose();
        });
      }, [text]);
  return null
}

export default Alert
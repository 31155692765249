import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { COffcanvas, COffcanvasBody } from "@coreui/react";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonComponent from "../Button/ButtonComponent";
import CustomModal from "../CustomModal/CustomModal";
import { RiQuestionnaireFill } from "react-icons/ri";
import { logoutApi } from "../../Constants/Api/Api";
import Alert from "../../Constants/alert/Alert";
// import logo from "../../assets/color_transparent.png";
// import logoicon from "../../assets/logoicon.png"
import logoicon from "../../assets/logoicon2.png"
import "./NavbarSection.scss";
import { Button } from "react-bootstrap";

const NavbarSection = () => {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const handleShow = () => {
    if (show === true) {
      setShow(false);
    }
    if (show === false) {
      setShow(true);
    }
  };
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  useEffect(() => {}, [token]);

  const expand = "sm";
  const offcanvasWidth = window.innerWidth >= 1200 ? "40%" : "80%";

  const handleClose = () => setShow(false);
  const [modalShow, setModalShow] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    setShowAlert(true); // Display the alert
    setAlertConfig({
      text: "You have been logged out successfully!",
      icon: "success", // Set your desired icon
    });
  
    // After a certain period, hide the alert
    setTimeout(() => {
      navigate("/login");
      setShowAlert(false);

    }, 2000); // Adjust the time as needed

    logoutApi(token)
      .then((res) => {
        // console.log("res", res);
      })
      .catch((error) => {
        console.log(error);
      });

  };
  return (
    <>
      <div className="NavbarSection">
        {showAlert && <Alert text={alertConfig.text} icon={alertConfig.icon} />}
        <Navbar expand={expand} className="navbackground">
          <Container>
            <Navbar.Brand href="/" className="Logotype">
              <Link to="/">
                <img src={logoicon} alt="logo" height="70px" />
              </Link>
              {/* <Link to="/" className="logoLink">
                <h3 style={{ color: "#fff",fontWeight:700 }}>Oh Wise</h3>
              </Link> */}
            </Navbar.Brand>
            <GiHamburgerMenu
              size={20}
              onClick={handleShow}
              color="white"
              className="Hamburger"
            />
            <COffcanvas
              className="sm xs"
              placement="end"
              visible={show}
              scroll={true}
              onHide={() => setShow(false)}
              style={{ width: offcanvasWidth }}
            >
              <COffcanvasBody>
                <Nav className="justify-content-end align-items-center flex-grow-1 ">
                  {token ? (
                    <>
                      <Nav.Item
                        className="navspace"
                        onClick={() => {
                          handleClose();
                          setActiveLink("home"); // Update active link
                        }}
                      >
                        <Nav.Link
                          className={activeLink === "home" ? "active-link" : ""} // Apply the active-link class if active
                        >
                          <Link to="/" className="link">
                            Home
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className="navspace"
                        onClick={() => {
                          handleClose();
                          setActiveLink("myqueries"); // Update active link
                        }}
                      >
                        <Nav.Link
                          className={
                            activeLink === "myqueries" ? "active-link" : ""
                          } // Apply the active-link class if active
                        >
                          <Link to="/myqueries" className="link">
                          My Questions
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className="navspace"
                        onClick={() => {
                          handleClose();
                          setActiveLink("queries"); // Update active link
                        }}
                      >
                        <Nav.Link
                          className={
                            activeLink === "queries" ? "active-link" : ""
                          } // Apply the active-link class if active
                        >
                          <Link to="/allqueries" className="link">
                          Explore Questions
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item
                        className="navspace"
                        onClick={() => {
                          handleClose();
                          setActiveLink("about"); // Update active link
                        }}
                      >
                        <Nav.Link
                          className={activeLink === "about" ? "active-link" : ""} // Apply the active-link class if active
                        >
                          <Link to="/about" className="link">
                            About 
                          </Link>
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item
                        className="navspace"
                        onClick={() => {
                          handleClose();
                          setModalShow(true);
                        }}
                      >
                        <Nav.Link
                          className={
                            activeLink === "question" ? "active-link" : ""
                          } // Apply the active-link class if active
                        >
                          <p className="askQuestion" style={{marginBottom:'0'}}>
                          Ask a Question
                          </p>
                        </Nav.Link>
                      </Nav.Item>

                      {/* <Nav.Item className="navspace">
                        <p
                        className="link"
                          onClick={() => {
                            handleClose();
                            setModalShow(true);
                          }}
                        >
                          Question
                        </p>
                      </Nav.Item> */}
                      <Nav.Item className="navspace">
                        <Nav.Link onClick={handleClose}>
                          <Link className="link">
                            {/* <ButtonComponent
                              name="Logout" 
                            /> */}
                            <Button onClick={handleLogout}>Logout</Button>
                          </Link>
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  ) : (
                    <>
                      <Nav.Link onClick={()=>{handleClose();setActiveLink("home");}} className={activeLink === "home" ? "active-link" : ""}>
                        <Link to="/" className="link">
                          Home
                        </Link>
                      </Nav.Link>

                      <Nav.Link
                      onClick={()=>{handleClose(); setActiveLink("queries");}}
                          className={
                            activeLink === "queries" ? "active-link" : ""
                          } // Apply the active-link class if active
                        >
                          <Link to="/allqueries" className="link">
                          Explore Questions
                          </Link>
                        </Nav.Link>
                      {/* <Nav.Link onClick={handleClose}>
                        <Link to="/myqueries" className="link">
                          My Queries
                        </Link>
                      </Nav.Link> */}
                      {/* <Nav.Link
                        onClick={() => {
                          handleClose();
                          setModalShow(true);
                        }}
                      >
                        <Link to="/add-queries" className="link">
                          Add Queries
                        </Link>
                      </Nav.Link> */}
                      <Nav.Link className="loginbtn" onClick={handleClose}>
                        <Link to="/login" className="link">
                          {/* <ButtonComponent name="Login" /> */}
                          <Button>Login</Button>
                        </Link>
                      </Nav.Link>
                    </>
                  )}
                </Nav>
              </COffcanvasBody>
            </COffcanvas>
          </Container>
        </Navbar>
        <CustomModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </>
  );
};
export default NavbarSection;

import React, { useEffect, useState } from "react";
import { Accordion, Container, Spinner } from "react-bootstrap";
import {  getProfileApi } from "../../Constants/Api/Api";
import ButtonComponent from "../../Components/Button/ButtonComponent";
import PaymentModal from "../../Components/PaymentModal/PaymentModal";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal/DeleteConfirmationModal";
import Pagination from "@mui/material/Pagination";
import usePagination from "../Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getMyQuery } from "../../redux/querySlice";
import "./MyQueries.scss";

const MyQueries = () => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteQueryId, setDeleteQueryId] = useState(null);
  const [myQuerie, setMyQuerie] = useState([]);
  const [question, setQuestion] = useState();
  const [postId, setPostId] = useState();
  const [questionId, setQuestionId] = useState();
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const data = useSelector((state) => state.myQueryReducer.cart);
  const loading = useSelector((state) => state.myQueryReducer.loading);

  const token = localStorage.getItem("userToken");

  const perpage = 10;
  const count = Math.ceil(data?.count / perpage);

  const _DATA = usePagination(myQuerie, perpage);
  const handleChange  = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const dispatch = useDispatch();

  const sortBy = "DESC";
  useEffect(() => {
    getProfileApi(token)
      .then((res) => {
        if (res.status === 200) {
          const id = res.data.data.id;
          setPostId(id);
          dispatch(getMyQuery({ token, id, perpage, page,sortBy})  );
          // myquestions(res.data.data.id);
        }
      })
      .catch((error) => console.log(error));
  }, [token, perpage, page, sortBy,dispatch]);

  const handlePay = (question, id) => {
    setQuestionId(id);
    setQuestion(question);
    setShowPaymentModal(true);
  };

  // const handleDelete = (id) => {
  //   // Show the delete confirmation modal when "delete" is clicked
  //   setShowDeleteModal(true);
  //   setDeleteQueryId(id);
  // };

  const confirmDelete = () => {
    // Handle the delete operation here
    console.log("Deleting query with ID: ", deleteQueryId);

    // Close the delete confirmation modal
    setShowDeleteModal(false);
  };

  return (
    <>
      <Container>
        <div className="myQueriesContainer">
          <div className="textContainer">
            <h1>My Queries</h1>
          </div>
          {loading ? (
            <div className="loading">
              <Spinner animation="border" />
              <h4>Please wait, data is fetching...</h4>
            </div>
          ) : (
            <>
            
            {data?.rows && data?.rows.length === 0 ? (
              <div className="noDataMessage">
                <h3 style={{textAlign:'center',margin:'20px auto'}}>No queries found!</h3>
              </div>
            ):(
            <Accordion className="myQuerie" defaultActiveKey="0">
              {data?.rows.map((query, index) => (
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header>{`Q: ${query.question}`} </Accordion.Header>
                  <Accordion.Body>
                    {query.is_paid ? (
                      query.answer && query.is_answered ? (
                        <p>Answer: {query.answer}</p>
                      ) : (
                        <p>Please wait for an answer</p>
                      )
                    ) : (
                      <div className="payedAnswer">
                        <h6>Pay to see the answer</h6>
                        <ButtonComponent
                          name="Pay"
                          onClick={() => handlePay(query.question, query.id)}
                        />
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
             )} </>
          )}
          {data?.rows > 10 && (
                  <div className="myPagination">
                    <Pagination
                      count={count}
                      color="secondary"
                      size="large"
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChange}
                    />
                  </div>
                )}
        </div>
      </Container>

      <PaymentModal
        postId={postId}
        question={question}
        showPaymentModal={showPaymentModal}
        questionId={questionId}
        setShowPaymentModal={setShowPaymentModal}
        page={page}
        perpage={perpage}
        sortBy={sortBy}
      />

      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={confirmDelete}
      />
    </>
  );
};

export default MyQueries;

import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
// import StripePayment from "../../Pages/PaymentForm/PaymentForm";
import StripePayment from "../../Pages/PaymentForm/test";

const PaymentModal = ({
  postId,
  question,
  showPaymentModal,
  setShowPaymentModal,
  questionId,
  page,
  perpage,
  sortBy
}) => {
  const [visibility, setVisibility] = useState("public");
  const handleVisibilityChange = (event) => {
    setVisibility(event.target.value);
  };


  return (
    <div>
      <Modal
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <StripePayment/> */}
          {/* <MyCheckoutForm/> */}
          <Form>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label style={{ fontWeight: 600 }}>
                Select Visibility
              </Form.Label>
              <Form.Select onChange={handleVisibilityChange}>
                <option value="public">Public</option>
                <option value="private">Private</option>
              </Form.Select>
            </Form.Group>
          </Form>
          <StripePayment
            postId={postId}
            question={question}
            setShowPaymentModal={setShowPaymentModal}
            questionId={questionId}
            page={page}
            perpage={perpage}
            sortBy={sortBy}
            visibility={visibility}
            setVisibility={setVisibility}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentModal;

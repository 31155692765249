import React from 'react'
import { Routes, Route } from "react-router-dom";
import Home from './Pages/Home/Home';
import Signup from './Pages/Signup/Signup';
import Login from './Pages/Login/Login';
import MyQueries from './Pages/MyQueries/MyQueries';
import SendOtp from './Pages/SendOtp/SendOtp';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Varification from './Pages/ForgotPassword/varification';
import About from './Pages/About/About';
import AllQueries from './Pages/AllQueries/AllQueries';
import Home1 from './Pages/Home1/Home1';
// import VerifyOtp from './Pages/VerifyOtp/VerifyOtp';
const WebRoutes = () => {
  return (
    <>
    <Routes>
        
        <Route exact path='/' element={<Home1/>}/>
        {/* <Route exact path='/' element={<Home/>}/> */}
        <Route exact path='/signup' element={<Signup/>}/>
        <Route exact path='/sendotp' element={<SendOtp/>}/>
        {/* <Route exact path='/verifyotp' element={<VerifyOtp/>}/> */}
        <Route exact path='/login' element={<Login/>}/>
        <Route exact path='/verification' element={<Varification/>}/>
        <Route exact path='/forgotpassword' element={<ForgotPassword/>}/>
        <Route exact path='/myqueries' element={<MyQueries/>}/>
        <Route exact path='/allqueries' element={<AllQueries/>}/>
        <Route exact path='/about' element={<About/>}/>
    </Routes>
    </>
  )
}

export default WebRoutes
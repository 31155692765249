import React from "react";
import { Button, Container, Form } from "react-bootstrap";
import "./MobileBanner.scss";
import Card from "../Card/Card";

const MobileBanner = ({ addQuestion, setAddQuestion, handleAddQuestion }) => {
  return (
    <>
      <div className="mobile_view_banner">
        <Container>
          <div className="banner_text">
            <h5>
              Welcome to <strong> Oh Wise 1 </strong>- Your Gateway to the
              Extraordinary World of
              <strong> Questions and Answers!</strong>
            </h5>
            <p>
              Are you ready to step into a realm where every question is a
              portal to fascinating knowledge?
            </p>
          </div>
          <div className="search_container">
            <Form.Control
              type="text"
              placeholder="Type Your Question...."
              value={addQuestion}
              onChange={(e) => setAddQuestion(e.target.value)}
            />
            <div style={{textAlign:'end'}}>
              <Button onClick={handleAddQuestion}>Ask Question</Button>
            </div>
          </div>
        </Container>
      </div>
      <div className="mob_banner_cards">
        {/* <div className="card_shadow">
          <div className="top"></div>
          <div className="bottom"></div>
        </div> */}  
      
        <Card />
        <div className="card_shadow">
        <div className="top_corner"></div>     
        <div className="top"></div>
          <div className="bottom"></div>
        <div className="bottom_corner"></div>     
        </div>
      </div>
    </>
  );
};

export default MobileBanner;

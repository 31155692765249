import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import user_card from "../../assets/user_card.png";
import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import img3 from "../../assets/img3.png";
import aboutImage1 from "../../assets/aboutimg1.png";
import aboutImage2 from "../../assets/aboutimg2.png";
import aboutImage3 from "../../assets/aboutimg3.png";
import sliderImage from "../../assets/sliderImage.png";
import coma from "../../assets/coma.png";
import { sliderdata } from "./dummyData";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import segmentImg from "../../assets/segmentImg.png";
import "./Home1.scss";
import Alert from "../../Constants/alert/Alert";
import PaymentModal from "../../Components/PaymentModal/PaymentModal";
import { getReviewApi, postQuestionApi } from "../../Constants/Api/Api";
import Card from "../../Components/Card/Card";
import MobileBanner from "../../Components/MobileBanner/MobileBanner";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Home1 = () => {
  const [addQuestion, setAddQuestion] = useState("");
  const [postId, setPostId] = useState();
  const [questionId, setQuestionId] = useState();
  const [question, setQuestion] = useState();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [review, setReview] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const token = localStorage.getItem("userToken");

  const handleAddQuestion = () => {
    if (token !== null) {
      const trimmedQuestion = addQuestion.trim();
      if (!trimmedQuestion) {
        // Check if addQuestion is empty and show an alert
        setShowAlert(true);
        setAlertConfig({
          text: "Please enter your question.",
          icon: "error",
        });
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      } else {
        postQuestionApi(addQuestion, token)
          .then((res) => {
            console.log("res", res.data);

            if (res.status === 201) {
              setPostId(res.data.data.user_id);
              setQuestionId(res.data.data.id);
              setQuestion(res.data.data.question);
              setShowPaymentModal(true);
              // setShowAlert(true);
              // setAlertConfig({
              //   text: res.data.message,
              //   icon: "success",
              // });
              setAddQuestion("");
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    } else {
      setShowAlert(true);
      setAlertConfig({
        text: "Please login first!",
        icon: "error",
      });
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  useEffect(() => {
    getReviewApi()
      .then((res) => {
        // console.log("res",res.data.data)
        if (res.status === 200) {
          setReview(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <>
      {showAlert && <Alert text={alertConfig.text} icon={alertConfig.icon} />}
      <div className="banner_section">
        <Container>
          <div className="banner_content">
            <h2>
              Welcome to<strong> Oh Wise 1</strong> - Your
              <br /> Gateway to the Extraordinary World
              <br /> of <strong>Questions and Answers!</strong>{" "}
            </h2>
            <p className="banner_text">
              Are you ready to step into a realm where every question is a
              portal to <br />
              fascinating knowledge?
            </p>
            <div className="btn_container">
              <Form.Control
                type="text"
                placeholder="Type Your Question...."
                value={addQuestion}
                onChange={(e) => setAddQuestion(e.target.value)}
              />
              <div>
                <Button onClick={handleAddQuestion}>Ask Question</Button>
              </div>
            </div>
          </div>
          <div className="banner_cards">
            <div className="card_shadow">
              <div className="top"></div>
              <div className="bottom"></div>
            </div>
            <Card />
          </div>

          {/* <div className="banner_card"> */}
          {/* <img src={user_card} alt="card" /> */}
          {/* </div> */}
        </Container>
      </div>
      <div className="mob_banner">
        <MobileBanner
          addQuestion={addQuestion}
          setAddQuestion={setAddQuestion}
          handleAddQuestion={handleAddQuestion}
        />
      </div>
      {/* section 2 */}
      <Container>
        <div className="section2">
          <section className="img_section1">
            <img
              src={img1}
              className="image1"
              alt="img1"
              width={310}
              height={350}
            />
            <img
              src={img2}
              className="image2"
              alt="img2"
              width={310}
              height={200}
            />
          </section>
          <section className="img_section2">
            <img src={img3} className="image3" alt="img3" width={285} />
          </section>
          <section className="img_section3">
            <span>
              <h1>Unleash Your Curiosity</h1>
            </span>
            <p>
              At Oh Wise 1, we celebrate the wonder of questions. There's no
              such thing as a question too simple, too complex, or too
              whimsical. Have you ever contemplated the mysteries of the
              universe, or maybe you're pondering the best way to start a
              campfire? Here, your every question is a key to unlock hidden
              treasures of wisdom.
            </p>
            <span>
              <Button>Get Started</Button>
            </span>
          </section>
        </div>
        <div className="section3">
          <div className="card1">
            <h3>How it works?</h3>
            <p className="text">
              <b>Ask Your Question :</b> It's as easy as a wizard's incantation.
              Type in your question, and let the magic begin. Our knowledge
              sorcerers are ready to unravel the mysteries you seek.
            </p>
            <p className="text">
              <b>Get the Inside Scoop :</b> Our friendly and experienced
              resident experts (your trusted guides) will craft responses that
              are not just informative but enchanting. We'll transform complex
              concepts into simple spells and illuminate the darkest corners of
              your curiosity.
            </p>
            <p className="text">
              <b>Pay with a Smile :</b> Unlock the joy of learning and
              exploration for just $5. It's the most affordable ticket to an
              adventure you'll never forget, and you'll leave with more wisdom
              than you ever imagined.
            </p>
            <img src={aboutImage1} alt="" />
          </div>
          <div className="card1">
            <h3>Why choose Oh Wise 1?</h3>
            <p className="text">
              <b>Curiosity Community :</b> Oh Wise 1 isn't just a place for
              questions and answers; it's a thriving community of inquisitive
              minds who share your passion for exploration. Here, you'll find a
              welcoming atmosphere that encourages collaboration and friendly
              discussions.
            </p>
            <p className="text">
              <b>All Ages Welcome :</b> Oh Wise 1 is a magical land where
              questions transcend generations. It's a family-friendly space
              where kids, teenagers, and adults of all ages can embark on an
              incredible journey of discovery.
            </p>
            <p className="text">
              <b>No Question Too Curious :</b> From the whimsical to the
              profound, we embrace every question with open arms. There's no
              query too peculiar, no inquiry too odd. We encourage you to set
              your curiosity free.
            </p>
            <img src={aboutImage2} alt="" />
          </div>
        </div>
        <div className="section3">
          <div className="card2">
            <h3>Let the questions begin!</h3>
            <p className="text">
              Ready to embark on your very own quest for knowledge? Step through
              the portal and join us in the enchanting world of Oh Wise 1 today!
              Start your journey by asking your first question now, and you'll
              be amazed by the treasures of wisdom that await you..
            </p>

            <img src={aboutImage3} alt="" />
          </div>
          <div className="card2">
            <h3>Why choose Oh Wise 1?</h3>
            <p className="text">
              Still pondering if this is the right place for you? Explore our
              extensive library of questions and answers that unveil the secrets
              of our community's collective curiosity. If you have any questions
              about the process, our magical guidelines, or any other inquiries,
              feel free to reach out. Our team of knowledge wizards is here to
              make your quest for wisdom an unforgettable experience.
            </p>

            <img src={aboutImage2} alt="" />
          </div>
        </div>

        <div className="section4">
          <h2>What our clients have to say</h2>
          <Carousel responsive={responsive}>
            {sliderdata.map((item,index) => (
              <div key={index} className="carousel_container">
                <section className="carousel_img_container">
                  <img src={item.image} alt="img" />
                </section>
                <section className="carousel_content_container">
                  <img src={coma} alt="coma" width={50} />

                  <p>{item.text}</p>
                  <div className="segment_container">
                    <span>
                      <h5>{item.name}</h5>
                      <p>{item.text2}</p>
                    </span>
                    {/* <img src={segmentImg} alt="img"   /> */}
                  </div>
                </section>
              </div>
            ))}
          </Carousel>
          {/* <div className="slider_Container">
            <Carousel indicators={false} className="w-100">
                {
                    sliderdata.map((item,index)=>{
                        <Carousel.Item key={index}>
                        <div className="slider_image">
                          <img src={item.sliderImage} alt="sliderImage" />
                        </div>
                        <div className="slider_content">
                            <img src={item.coma} alt="coma"/>
                         
                          <p>
                         {item.text}
                          </p>
                        </div>
                      </Carousel.Item>
                    })
                }
           
            </Carousel>
          </div> */}
        </div>
      </Container>
      <PaymentModal
        postId={postId}
        question={question}
        showPaymentModal={showPaymentModal}
        setShowPaymentModal={setShowPaymentModal}
        questionId={questionId}
      />
    </>
  );
};

export default Home1;

import React from "react";
import "./About.scss";
const About = () => {
  return (
    <div className="aboutUs">
      <div className="howItsWork">
      <h3>About Us</h3>
        <h4>How It Works</h4>
        <p>
          <strong>Ask Your Question:</strong>It&#39;s as easy as a wizard&#39;s
          incantation. Type in your question, and let the magic begin. Our
          knowledge sorcerers are ready to unravel the mysteries you seek.
        </p>
        <p>
          <strong>Get the Inside Scoop:</strong>Our friendly and experienced
          resident experts (your trusted guides) will craft responses that are
          not just informative but enchanting. We&#39;ll transform complex
          concepts into simple spells and illuminate the darkest corners of your
          curiosity.
        </p>
        <span>
          <strong>Pay with a Smile:</strong>Unlock the joy of learning and
          exploration for just $5. It&#39;s the most affordable ticket to an
          adventure you&#39;ll never forget, and you&#39;ll leave with more
          wisdom than you ever imagined.
        </span>
        <h4>Why Choose Oh Wise 1?</h4>
        <p>
          <strong>Curiosity Community:</strong>Oh Wise 1 isn&#39;t just a place
          for questions and answers; it&#39;s a thriving community of
          inquisitive minds who share your passion for exploration. Here,
          you&#39;ll find a welcoming atmosphere that encourages collaboration
          and friendly discussions.
        </p>
        <p>
          <strong>All Ages Welcome:</strong>Oh Wise 1 is a magical land where
          questions transcend generations. It&#39;s a family-friendly space
          where kids, teenagers, and adults of all ages can embark on an
          incredible journey of discovery.
        </p>
        <p>
          <strong>No Question Too Curious:</strong>From the whimsical to the
          profound, we embrace every question with open arms. There&#39;s no
          query too peculiar, no inquiry too odd. We encourage you to set your
          curiosity free.
        </p>
        <h4>Let the Questions Begin!</h4>
        <p>
          Ready to embark on your very
          own quest for knowledge? Step through the portal and join us in the
          enchanting world of Oh Wise 1 today! Start your journey by asking your
          first question now, and you&#39;ll be amazed by the treasures of
          wisdom that await you.
        </p>
        <h4>[Ask Your First Question]</h4>
        <p>
          Still pondering if this is
          the right place for you? Explore our extensive library of questions
          and answers that unveil the secrets of our community&#39;s collective
          curiosity. If you have any questions about the process, our magical
          guidelines, or any other inquiries, feel free to reach out. Our team
          of knowledge wizards is here to make your quest for wisdom an
          unforgettable experience.
        </p>
      </div>
    </div>
  );
};

export default About;

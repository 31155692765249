import React, { useState } from "react";
import ButtonComponent from "../../Components/Button/ButtonComponent";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Alert from "../../Constants/alert/Alert";
import { register } from "../../Constants/Api/Api";
import "./Signup.scss";

const Signup = () => {
  const [validEmail, setValidEmail] = useState(true);
  const [validMobile, setValidMobile] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobile: "",
    password: "",
    confirm_password: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });

  const navigate = useNavigate();

  const handleShowPass = () =>
    setShowPassword((showPassword) => !showPassword);

  const handleMouseDownPass = (event) => {
    event.preventDefault();
  };

  const handleUsernameChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim(); // Remove leading and trailing spaces
  
    if (name === "username") {
      if (trimmedValue.length >= 5 || trimmedValue === "") {
        setUsernameError(false);
      } else {
        setUsernameError(true);
      }
    }
  
    setFormData({ ...formData, [name]: value }); // Update the state with the original value
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setFormData({ ...formData, email: enteredEmail });
    const isEmailValid = validateEmail(enteredEmail);
    setValidEmail(isEmailValid);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleMobileChange = (e) => {
    const enteredMobile = e.target.value;
    setFormData({ ...formData, mobile: enteredMobile });
    setValidMobile(isMobileValid(enteredMobile));
  };

  const isMobileValid = (mobile) => {
    // Define a regex pattern for a valid mobile number
    const mobileRegex = /^[0-9]{10}$/; // Assumes a 10-digit mobile number

    // Use the regex test method to check if the mobile number matches the pattern
    return mobileRegex.test(mobile);
  };

  const handlePasswordChange = (e) => {
    const enteredPassword = e.target.value;
    setFormData({ ...formData, password: enteredPassword });
    if (enteredPassword.trim() === "") {
      setPasswordError(false);
    } else {
      setPasswordError(!isPasswordStrong(enteredPassword));
    }


  };

  const isPasswordStrong = (enteredPassword) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+<>?/{}~]).{8,}$/;
    return passwordRegex.test(enteredPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const enteredConfirmPassword = e.target.value;
    setFormData({ ...formData, confirm_password: enteredConfirmPassword });
    if (enteredConfirmPassword === "") {
      setPasswordMatchError(false);
    } else {
      setPasswordMatchError(formData.password !== enteredConfirmPassword);
    }
  };

  const handleConfirmPasswordBlur = () => {
    validatePasswords();
  };

  const validatePasswords = () => {
    if (formData.password !== formData.confirm_password) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  };

  const handleSubmit = () => {
    
    register(formData)
      .then((res) => {
        console.log("res",res)
        if (res.status === 201) {
          setShowAlert(true);
          setAlertConfig({
            text: res.data.message,
            icon: "success",
          });
          setTimeout(() => {
            setShowAlert(false);
            navigate("/sendotp", { state: formData.email });
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.response && error.response.status === 400) {
          setShowAlert(true);
          setAlertConfig({
            text: error.response.data.message,
            icon: "error",
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      });
  };

  const handleAlertSubmit = () => {
    const missingFields = [];

    if (!formData.username ) missingFields.push("Username");
    if (formData.username.length <= 5) missingFields.push("Username must be greater than 5 characters")
    if (!formData.email || !validEmail) missingFields.push("Email");

    // if (!formData.mobile) missingFields.push("Mobile");
    if (!formData.password) missingFields.push("Password");

    if (!formData.confirm_password) missingFields.push("Confirm Password");
    if (!isPasswordStrong(formData.password)) {
      missingFields.push("Password is not strong enough");
    }

    if (formData.password !== formData.confirm_password) {
      missingFields.push("Passwords must match");
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }

    if (missingFields.length > 0) {
      setShowAlert(true);
      setAlertConfig({
        icon: "info",
        text: `Please fill in the following required fields: ${missingFields.join(
          ", "
        )}.`,
      });
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } else {
      handleSubmit();
    }
  };

  return (
    <div className="SignupContainer">
      {showAlert && <Alert text={alertConfig.text} icon={alertConfig.icon} />}
      <div className="Signup">
        <div className="Signup_Container">
          <h2 className="mb-4">Sign Up</h2>
          <Form>
            <FormGroup>
              <FormLabel>Username</FormLabel>
              <FormControl
                type="text"
                placeholder="Username"
                name="username"
                value={formData.username}
                onChange={handleUsernameChange}
              />
               {usernameError && (
              <p style={{ color: "red", marginTop: "0.5rem",textAlign:'start' }}>
                Username must be greater than 5 characters.
              </p>
            )}
            </FormGroup>
            <FormGroup>
              <FormLabel className="mt-3">Email</FormLabel>
              <FormControl
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={(e) => handleEmailChange(e)}
                isInvalid={!validEmail}
              />
              <FormControl.Feedback type="invalid" style={{ color: "red" }}>
                Invalid email format
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup>
              <FormLabel className="mt-3">Mobile Number (Optional)</FormLabel>
              <FormControl
                type="tel" // Use type "tel" for mobile numbers
                placeholder="Mobile Number"
                name="mobile"
                value={formData.mobile}
                onChange={handleMobileChange} // Use handleMobileChange for mobile input
                isInvalid={!validMobile}
                
              />
              <FormControl.Feedback type="invalid" style={{ color: "red" }}>
                Invalid mobile number format
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup>
              <FormLabel className="mt-3">Password</FormLabel>
              <div className="input-container">
                <FormControl
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handlePasswordChange}
                  onInvalid={!passwordError}
                />
                <span className="eyesHidden">
                  <p
                    onClick={handleShowPass}
                    onMouseDown={handleMouseDownPass}
                  >
                    {showPassword ? (
                      <AiFillEye size={25} />
                    ) : (
                      <AiFillEyeInvisible size={25} />
                    )}
                  </p>
                </span>
              </div>
              {passwordError && (
                <p className="error">
                  Password must be at least 8 characters long and contain at
                  least one uppercase letter, one special character, and one
                  number.
                </p>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel className="mt-3">Confirm Password</FormLabel>
              <div className="input-container">
                <FormControl
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleConfirmPasswordChange}
                  onBlur={handleConfirmPasswordBlur}
                />
                <span className="eyesHidden">
                  <p
                    onClick={handleShowPass}
                    onMouseDown={handleMouseDownPass}
                  >
                    {showPassword ? (
                      <AiFillEye size={25} />
                    ) : (
                      <AiFillEyeInvisible size={25} />
                    )}
                  </p>
                </span>
              </div>
              {passwordMatchError && (
                <p style={{ color: "red", textAlign: "start" }}>
                  Passwords do not match
                </p>
              )}
            </FormGroup>
          </Form>
          <div className="mt-4">
            <ButtonComponent name="Sign Up" onClick={handleAlertSubmit} />
          </div>
          <p>
            Already have an account?<Link to="/login">Sign In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;

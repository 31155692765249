import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { getAllQuestionApi } from "../../Constants/Api/Api";
import Pagination from "@mui/material/Pagination";
import usePagination from "../Pagination";
import "./AllQueries.scss";
const AllQueries = () => {
  const [allQuestions, setAllQuestions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data_count, setCount] = useState();
  let [page, setPage] = useState(1);

  const PER_PAGE = 10;
  const count = Math.ceil(data_count / PER_PAGE);
  const _DATA = usePagination(allQuestions, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const maxWords = 100;

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const is_answered = 1;
  const sortBy = "DESC";
  useEffect(() => {
    setLoading(true);
    getAllQuestionApi(PER_PAGE, page, is_answered, sortBy)
      .then((res) => {
        // console.log("res",res)
        if (res.status === 200) {
          setCount(res.data.data.count);
          setAllQuestions(res.data.data.rows);
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);
      });
  }, [page, PER_PAGE]);

  return (
    <div>
      <Container>
        <div className="allQueries">
          <h4>Lorem Ipsum is simply dummy text</h4>

          {loading ? (
            <div className="text-center">
              <div className="loading">
              <Spinner animation="border" />
              <h4>Please wait, data is fetching...</h4>
            </div>
            </div>
          ) : allQuestions.length === 0 ? (
            <h3 style={{ textAlign: "center", marginTop: "20px" }}>
              No questions found
            </h3>
          ) : (
            allQuestions.map((item, index) => (
              <div className="question_list" key={index}>
                <div className="divider"></div>
                <div className="list_item">
                  <div className="question_ans">
                    <h5>Q:{item?.question}</h5>
                    <p>
                      Ans:{" "}
                      {item?.answer &&
                        (expanded || item.answer.split(" ").length <= maxWords
                          ? item.answer
                          : `${item.answer
                              .split(" ")
                              .slice(0, maxWords)
                              .join(" ")} ... `)}
                      {item.answer &&
                        item.answer.split(" ").length > maxWords && (
                          <span className="show-more" onClick={toggleExpand}>
                            {expanded ? " Show less" : " Show more"}
                          </span>
                        )}
                    </p>
                  </div>

                  <div>
                    <p className="userinfo">
                      Asked By {item?.post_user?.user_name}
                    </p>
                    <p className="userinfo">{item?.created_at}</p>
                  </div>
                </div>
              </div>
            ))
          )}
          {allQuestions.length > 10 && (
            <div className="pagination">
              <Pagination
                color="secondary"
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AllQueries;

import React from "react";
import Modal from "react-bootstrap/Modal";
import ButtonComponent from "../../Components/Button/ButtonComponent";
import "./DeleteConfirmationModal.scss"
import { Button } from "react-bootstrap";
const DeleteConfirmationModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this query?</Modal.Body>
      <Modal.Footer>
        <Button className="cancelbtn"  onClick={handleClose} >Cancel</Button>
        <Button className="confirmbtn" onClick={handleDelete} >Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;

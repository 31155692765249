import React, { useState } from "react";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import facebook from "../../assets/facebook-black.png";
import youtube from "../../assets/Youtube.png";
import twitter from "../../assets/Twitter.png";
import vimeo from "../../assets/Vimeo.png";
import Alert from "../../Constants/alert/Alert";
import { postReviewApi } from "../../Constants/Api/Api";
import "./Footer.scss";

const Footer = () => {
  const [addReview, setAddReview] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  
  const token = localStorage.getItem("userToken");
  const handleAddReview = () => {
    if (token !== null) {
      if (!addReview.trim()) {
        // Check if addReview is empty (or contains only whitespace)
        setShowAlert(true);
        setAlertConfig({
          text: "Please enter a review before submitting.",
          icon: "error",
        });
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      } else {
        postReviewApi(addReview, token)
          .then((res) => {
            // console.log("res", res);
            if (res.status === 201) {
              setShowAlert(true);
              setAlertConfig({
                text: "Review Added",
                icon: "success",
              });
              setAddReview("");
            }
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
          })
          .catch((error) => {
            console.log("error", error);
          });

         
      }
    } else {
      setShowAlert(true);
      setAlertConfig({
        text: "Please login first!",
        icon: "error",
      });
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  return (
    
    <div className="footer_section">
      {showAlert && <Alert text={alertConfig.text} icon={alertConfig.icon} />}
      <Container>
      <section className="wrapper">
        <h1>Get In Touch</h1>
        <div className="add_reviwe">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Add Review"
              aria-describedby="basic-addon2"
              value={addReview}
              onChange={(e) => setAddReview(e.target.value)}
            />
            <Button
              style={{ backgroundColor: "#7D1CB2", color: "white" }}
              variant="outline-secondary"
              id="button-addon2"
              onClick={handleAddReview}
            >
              Add Review
            </Button>
          </InputGroup>
          {/* <Form.Control type="text" placeholder="Name">
        </Form.Control>
        <button>ADD Review</button> */}
        </div>
      </section>
      {/* <div className="icons_section">
        <section className="icons">
          <img src={facebook} alt="facebook" />
          <img src={vimeo} alt="vemio" />
          <img src={twitter} alt="twitter" />
          <img src={youtube} alt="youtube" />
        </section>
      </div> */}

      <div className="tradeMark_section">
        <section className="content">
          <p className="footer_text">© {(new Date()).getFullYear()} OhWise1. All rights reserved.</p>
          <h3>OhWise1!</h3>
          <span className="services">
            <p>Terms of Service</p>
            <p>Privacy Policy</p>
          </span>
          <p className="mob_text">© {(new Date()).getFullYear()} OhWise1. All rights reserved.</p>
        </section>
      </div>
      </Container>
     
    </div>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import ButtonComponent from "../../Components/Button/ButtonComponent";
import { getKeyApi, stripePayment } from "../../Constants/Api/Api";
import { Container, FormControl } from "react-bootstrap";
import Alert from "../../Constants/alert/Alert";
import "./PaymentForm.scss";
import { useDispatch } from "react-redux";
import { getMyQuery } from "../../redux/querySlice";
import { updateQuestionApi } from "../../Constants/Api/Api";

// public key
const stripePromise = loadStripe(
  "pk_test_51OATrIFwChoewCDF1qz6vzstMAQoiWdaVDCKA9tEmFlsiHj9OSnSBxbyyMUf64JDYo0hrMen99FdNJtEReLWaZhi00tgBEs2So"
);

function MyCheckoutForm({
  postId,
  question,
  setShowPaymentModal,
  questionId,
  page,
  perpage,
  sortBy,
  visibility,
  setVisibility
}) {

  const [amount, setAmount] = useState(5);
  const [publishKey, setPublishKey] = useState();
  const [showAlert, setShowAlert] = useState(false);
  // const [page, setPage] = useState(1);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const dipatch = useDispatch();
  
  // const PER_PAGE = 10;
  //   console.log("publishKey",publishKey)
  const token = localStorage.getItem("userToken");
  const paymentMethod = "card";
  useEffect(() => {
    getKeyApi(token, paymentMethod)
      .then((res) => {
        //   console.log("res",res)
        setPublishKey(res.data.data.publishable_key);
      })
      .catch((error) => {
        console.log("error111", error);
      });
  }, [token]);
  const stripe = useStripe();
  const elements = useElements();
 
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const isPrivate = visibility === 'private' ? 1 : 0;

    if (!stripe || !elements) {
      return;
    }
    stripePayment(token, amount, questionId, question)
      .then(async (response) => {
        console.log("Success:", response);
        const clientSecret = response.data.data.clientSecret;

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });
        if (result.error) {
          // Show an error to your customer (e.g., insufficient funds)
          console.error("check error:", result.error.message);
          setShowAlert(true);
          setAlertConfig({
            text: result.error.message,
            icon: "error",
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === "succeeded") {
            // Handle the successful payment
            console.log("Payment succeeded");
            setShowPaymentModal(false);
            dipatch(getMyQuery({ token, id: postId, perpage, page,sortBy }));
            setShowAlert(true);
            setAlertConfig({
              text: "Payment succeeded!",
              icon: "success",
            });
            setTimeout(() => {
              updateQuestionApi(questionId, isPrivate)
              .then((res) => {
                // console.log('res',res)
              })
              .catch((error)=>{
                // console.log("error",error)
              })
              setVisibility('public')
              setShowAlert(false);
            }, 2000);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShowAlert(true);
        setAlertConfig({
          text: "Payment failed. Please try again.",
          icon: "error",
        });
      });
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  return (
    <div className="payment_form_container">
      <Container>
        <div className="form-group mt-4">
          <label htmlFor="amount">Amount</label>
          <FormControl
            id="amount"
            placeholder="Enter Amount"
            value={amount}
            type="number"
            disabled
          />
        </div>
        <div className="form-group card-element">
          <CardElement />
        </div>
        <ButtonComponent name={`pay $${amount}`} onClick={handleSubmit} />
      </Container>
      {showAlert ? (
        <Alert text={alertConfig.text} icon={alertConfig.icon} />
      ) : null}
    </div>
  );
}

const StripePayment = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <MyCheckoutForm
        postId={props.postId}
        question={props.question}
        setShowPaymentModal={props.setShowPaymentModal}
        questionId={props.questionId}
        page={props.page}
        perpage={props.perpage}
        sortBy={props.sortBy}
        visibility={props.visibility}
        setVisibility={props.setVisibility}
      />
    </Elements>
  );
};

export default StripePayment;

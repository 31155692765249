import aboutImage1 from "../../assets/aboutimg1.png"
import aboutImage2 from "../../assets/aboutimg2.png"
import aboutImage3 from "../../assets/aboutimg3.png"


// import sliderImage from "../../assets/sliderImage.png";
import sliderImage1 from "../../assets/sliderImage1.jpg";
import sliderImage2 from '../../assets/sliderImage2.jpg';
import sliderImage3 from '../../assets/sliderImage3.jpg'
import coma from "../../assets/coma.png"
const data = [
    {
        heading:'How it works?',
        tex1:"Ask Your Question : It's as easy as a wizard's incantation. Type in your question, and let the magic begin. Our knowledge sorcerers are ready to unravel the mysteries you seek.",
        text2:"Get the Inside Scoop : Our friendly and experienced resident experts (your trusted guides) will craft responses that are not just informative but enchanting. We'll transform complex concepts into simple spells and illuminate the darkest corners of your curiosity.",
        text3:"Pay with a Smile : Unlock the joy of learning and exploration for just $5. It's the most affordable ticket to an adventure you'll never forget, and you'll leave with more wisdom than you ever imagined.",
        Image:aboutImage1
    },
    {
        heading:'Why choose Oh Wise 1?',
        tex1:"Curiosity Community : Oh Wise 1 isn't just a place for questions and answers; it's a thriving community of inquisitive minds who share your passion for exploration. Here, you'll find a welcoming atmosphere that encourages collaboration and friendly discussions.",
        text2:"All Ages Welcome : Oh Wise 1 is a magical land where questions transcend generations. It's a family-friendly space where kids, teenagers, and adults of all ages can embark on an incredible journey of discovery.",
        text3:"No Question Too Curious : From the whimsical to the profound, we embrace every question with open arms. There's no query too peculiar, no inquiry too odd. We encourage you to set your curiosity free.",
        Image:aboutImage2
    },
    {
        heading:'Let the questions begin!',
        tex1:"Ready to embark on your very own quest for knowledge? Step through the portal and join us in the enchanting world of Oh Wise 1 today! Start your journey by asking your first question now, and you'll be amazed by the treasures of wisdom that await you.",
       
        Image:aboutImage3
    },
    {
        heading:'Why choose Oh Wise 1?',
        tex1:"Still pondering if this is the right place for you? Explore our extensive library of questions and answers that unveil the secrets of our community's collective curiosity. If you have any questions about the process, our magical guidelines, or any other inquiries, feel free to reach out. Our team of knowledge wizards is here to make your quest for wisdom an unforgettable experience.",
       
        Image:aboutImage3
    },
]


export const sliderdata = [
    {
image :sliderImage1,
commaImage : coma,
text:"Oh Wise 1 is my go-to destination for satisfying my curious mind. The community is friendly, and the answers provided are not only informative but also delivered with a touch of humor. It's the perfect blend of learning and enjoyment!",
name:'Sarah J.',
text2:'Curious Explorer' 
},
{
    image :sliderImage2,
    commaImage : coma,
    text:"I've been a part of several Q&A platforms, but Oh Wise 1 stands out for its welcoming atmosphere. The experts here don't just provide answers; they make the whole learning experience enjoyable. It's more than a platform; it's a community that encourages curiosity",
    name:'Alex M.',
    text2:' Inquisitive Mind' 
    },
    {
        image :sliderImage3,
        commaImage : coma,
        text:"As a parent, I love that Oh Wise 1 is family-friendly. My kids and I enjoy asking questions together, and the platform provides a safe and fun environment for them to learn. It's become a part of our routine to explore and discover new things here!",
        name:'Emily H.',
        text2:'Mom and Curiosity Advocate' 
        },
]